import { createI18n } from "vue-i18n";

const i18n = createI18n({
  locale: "id",
  messages: {
    id: {
      Biaya: "Biaya per tahun | Biaya per 3 bulan",
      recommended: "Paket Rekomendasi Untukmu",
      get_solution:
        "Dapatkan solusi manajemen dokumen perusahaan Anda dengan paket harga terbaik jadi #LebihCepatMakinHemat",
      others_packages: "Pilihan Paket Lain",
    },
    en: {
      others_packages: "Other Packages",
      recommended: "Recommended Package for You",
      get_solution:
        "Get your company’s document management solution with the best price package so it's #LebihCepetMakinHemat",
      Biaya: "Annual fee | Cost every 3 months",
      "Coba Gratis": "Try for free",
      Gambaran: "Images",
      Fitur: "Feature",
      Blog: "Blog",
      Harga: "Plan & Pricing",
      Sebelumnya: "Previous",
      Kirim: "Send",
      Selanjutnya: "Next",
      "Kontak Sales": "Contact Sales",
      Triwulan: "Quarterly",
      Tahunan: "Annually",
      "Rekomendasi Add-ons": "Add-ons Recomendation",
      "Pilihan Add-ons untuk kebutuhan dan preferensi perusahan Anda":
        "A selection of Add-ons for your company’s needs and preferences",
      "Tambahan Pengguna": "Additional User",
      "Penambahan pengguna yang dapat mengakses akun Anda":
        "Adding users who can access your account",
      "Rp 35.000 / pengguna / bulan": "Rp 35.000 / user / month",
      "Peningkatan Penyimpanan": "Storage Upgrade",
      "Penambahan pengguna eksternal untuk signing dokumen Anda":
        "Addition of your document data storage",
      "Mulai dari Rp 30.000 / bulan": "Starting from Rp 30.000 / month",
      "Tambahan Dokumen Eksternal": "Additional External User",
      "Penambahan pengguna eksternal buat signing dokumen Anda":
        "Add an external user for signing your document",
      "Rp 10.000 / pengguna / bulan": "Rp 10.000 / user / month",
      "Pilihan Add-ons untuk kebutuhan enterprise perusahan Anda":
        "Add-ons specifically for company’s Corporate plan",
      "Koneksi API": "API Set-up",
      "Mengintegrasikan MomofinGO ke dalam perangkat lunak perusahaan anda":
        "Integrate MomofinGO into your enterprise software",
      "Rp 10.000.000 / 25 000 API Call": "Rp 10.000.000 / 25 000 API Call",
      "On Premise Set-up": "On Premise Set-up",
      "Menyimpan dokumen secara lokal di server anda":
        "Store documents locally on your server",
      "Rp 25.000.000 (one time)": "Rp 25.000.000 (one time)",
    },
  },
  // something vue-i18n options here ...
});

export default i18n;
